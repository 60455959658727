body.home,
body.page-template-cikf {

	// keyvisual
	// ---------

	#keyvisual {
		>img {
			margin-bottom: 10px;
		}

		ul.banner-area {
			list-style: none;
			margin: 0;
			padding: 0 10px;

			li {
				a {
					display: table;
					width: 100%;
					border: 10px solid $white;
					-webkit-box-shadow: 0 0 3px #ccc;
					-moz-box-shadow: 0 0 3px #ccc;
					box-shadow: 0 0 3px #ccc;
					position: relative;

					&:before {
						content: "";
						position: absolute;
						bottom: -10px;
						right: -10px;
						width: 0;
						height: 0;
						border: 30px solid transparent;
						border-right: 0 solid transparent;
						border-bottom: 30px solid $primary-color;
					}

					&:after {
						position: absolute;
						bottom: -11px;
						right: -5px;
						font-family: FontAwesome;
						content: "\f0d7";
						color: #fff;
						font-size: 13px;
					}

					img,
					span {
						display: table-cell;
					}

					img {
						width: 100%;
					}

					span {
						background: $secondary-color;
						color: $primary-color;
						width: 78%;
						vertical-align: middle;
						text-align: center;
						font-weight: bold;
						padding: 10px;
						line-height: 1.3
					}
				}

				&:first-child {
					margin-bottom: 10px;
				}
			}
		}
	}

	// main
	// ---------

	main {
		padding-top: 42px !important;

		>section {
			padding-top: 42px;
		}
	}

	// news
	// ---------

	#section-news {
		ul {
			list-style: none;
			margin: 0;

			li {
				margin-bottom: 10px;

				time {
					color: #666;
					font-size: 13px;
					display: block;
					border-bottom: 1px dotted #ddd;
					padding-bottom: 3px;
					margin-bottom: 3px;
					width: 100px;

					&:before {
						font-family: FontAwesome;
						content: "\f073";
						margin-right: 6px;
						font-size: 12px;
					}
				}
			}
		}

		a.button {
			border: 1px solid $primary-color;
			border-radius: 60px;
			background: $white;
			color: $primary-color;
			padding: 10px 30px 10px 40px;
			margin: 15px 0 0;
			position: relative;

			&:before {
				font-family: FontAwesome;
				content: "\f138";
				font-size: 12px;
				position: absolute;
				top: 11px;
				left: 10px;
			}

			&:hover {
				background: rgba($primary-color, 0.8);
				color: $white;
			}
		}
	}

	// about & training
	// ---------

	#section-about,
	#section-training {
		>div {
			>figure {
				margin-bottom: 1rem;
			}

			>section {
				&:not(#objectives) {
					>h1 {
						color: $primary-color;
						font-size: 18px;
						font-weight: bold;
						border-bottom: 1px solid;
						padding-bottom: 10px;
						margin-bottom: 15px;

						&:before {
							font-family: FontAwesome;
							content: "\f1db";
							margin-right: 6px;
							font-size: 13px;
							font-weight: normal;
							vertical-align: 2px;
						}
					}

					ol {
						li {
							padding-left: 0.5rem;
						}
					}

					dl {
						dt {
							margin-top: 1rem;

							&:first-child {
								margin-top: 0;
							}
						}

						dd {
							dl {
								font-size: 15px;

								dt {
									font-weight: normal;
								}
							}
						}

						&.training-course {
							>dt {
								border-bottom: 1px dotted $medium-gray;
								padding-bottom: 5px;
								margin-bottom: 10px;
							}

							>dd {
								margin-bottom: 20px;

								dl {
									background: #f7f7f7;
									padding: 10px;

									ul {
										margin-bottom: 0;
									}
								}

								@include breakpoint(medium up) {
									margin-bottom: 30px;

									dl {
										padding: 20px;
									}
								}
							}
						}
					}
				}

				&#readers {
					>section {
						margin-bottom: 30px;

						>h1 {
							font-size: 16px;
							font-weight: bold;
							margin-bottom: 15px;
						}

						ul {
							list-style: none;
							margin: 0;

							li {
								margin-bottom: 10px;
							}

							div {
								border: 1px solid #ddd;
								text-align: center;
								padding: 10px;
								border-radius: 3px;

								p {
									height: 63px;
									font-size: 15px;
									line-height: 1.4;
								}

								img {
									min-height: 110px;
								}
							}
						}
					}
				}

				&#objectives {
					text-align: center;

					>h1 {
						color: $alert-color;
						font-size: 18px;
						font-weight: bold;
					}

					ul {
						list-style: none;
						margin: 0;

						li {
							section {
								h1 {
									background: $alert-color;
									color: $white;
									font-size: 18px;
									padding: 10px;
								}

								h2 {
									color: rgba($primary-color, 0.6);
									font-weight: bold;
								}

								figure {
									margin-bottom: 10px;
								}

								ul {
									margin-bottom: 15px;

									li {
										color: $primary-color;
										font-size: 14px;
										font-weight: bold;

										&:before {
											font-family: FontAwesome;
											content: "\f1db";
											font-size: 10px;
											margin-right: 6px;
											vertical-align: 2px;
										}
									}
								}
							}

							&:last-child {
								section ul {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	#section-training {
		>div {
			>figure {
				margin-bottom: 2rem;
			}
		}
	}

	// editor
	// ---------

	#section-editor {
		section {
			text-align: center;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			h1 {
				background: #f1f1f1;
				font-size: 18px;
				font-weight: bold;
				padding: 10px;
				margin-bottom: 10px;
			}

			ul {
				list-style: none;
				margin: 0;

				li {
					margin-bottom: 20px;

					&:last-child {
						margin-bottom: 0;
					}

					figure {
						margin-bottom: 6px;
					}

					figcaption {
						span {
							display: block;
							text-align: left;

							&:first-child {
								font-weight: bold;
								margin-bottom: 6px;
								font-style: italic;
								text-align: center;
							}

							&:last-child {
								font-size: 15px;
								line-height: 1.5;
							}
						}
					}
				}
			}
		}
	}

	// download
	// ---------

	#section-download {
		ul {
			text-align: center;
			list-style: none;
			margin: 0;

			li {
				margin-bottom: 20px;

				figure {
					width: 60%;
					margin: 0 auto 5px;
				}

				figcaption {
					margin-bottom: 10px;
				}

				a.button {
					border-radius: 60px;
					background: $secondary-color;
					color: $primary-color;
					padding: 10px 30px 10px 40px;
					position: relative;

					&:before {
						font-family: FontAwesome;
						content: "\f138";
						font-size: 12px;
						position: absolute;
						top: 11px;
						left: 10px;
						color: $white;
					}

					&:hover {
						background: darken($secondary-color, 5%);
					}
				}
			}
		}
	}

	// price
	// ---------

	#section-price {
		table {
			border: 1px solid #ddd;
			border-collapse: collapse;
			font-size: 14px;

			thead {
				border: none;

				tr {
					border-bottom: 1px solid #ddd;
				}

				th {
					border-right: 1px solid #ddd;
					text-align: center;
				}
			}

			tbody {
				border: none;

				tr {
					background: none;
					border-bottom: 1px solid #ddd;
				}

				th {
					border-right: 1px solid #ddd;
					width: 70%;
				}

				td {
					text-align: center;
					width: 30%;
				}

				span.strike {
					color: $alert-color;
					text-decoration: line-through;
				}
			}

			strong {
				color: $alert-color;
			}
		}
	}

	// inquiry
	// ---------

	#section-inquiry {
		padding-bottom: 30px;

		form {
			.row {
				margin-bottom: 20px;
			}

			label {
				margin-bottom: 0;
				color: $primary-color;
			}

			button[type="submit"],
			input[type="submit"] {
				border-radius: 60px;
				padding: 10px 40px;
				margin: 20px 0 0;
			}

			input,
			textarea {
				margin-bottom: 5px;
			}
		}
	}


	@include breakpoint(medium up) {
		#keyvisual {
			position: relative;
			margin-bottom: 30px;

			ul.banner-area {
				position: absolute;
				bottom: -50px;
				text-align: center;
				padding: 0;

				li {
					display: inline-block;
					width: 45%;
					margin: 0 1%;

					&:first-child {
						margin-bottom: 0;
					}
				}
			}
		}

		#section-about {
			section#objectives {
				h2 {
					font-size: 18px;
				}
			}
		}

		#section-editor {
			section {
				ul {
					li {
						display: table;

						figure,
						figcaption {
							display: table-cell;
							vertical-align: middle;
						}

						figure {
							width: 200px;
						}

						figcaption {
							span {
								text-align: left !important;
							}
						}
					}
				}
			}
		}

		#section-download {
			text-align: center;

			ul {
				margin-bottom: 40px;
				overflow: hidden;

				li {
					float: left;
					width: 28%;
					margin: 0 calc(16% / 6);

					figure {
						margin: 0 0 10px;
						width: 100%;
					}

					figcaption {
						min-height: 3rem;
					}
				}
			}
		}

		#section-price {
			table {
				font-size: 16px;
			}
		}
	}


	@include breakpoint(large up) {
		#keyvisual {
			background: #1c345a;
			text-align: center;

			>img {
				margin-bottom: 0;
			}

			ul.banner-area {
				width: $global-width;
				left: 50%;
				bottom: -70px;
				margin-left: -530px;

				li {
					a {
						span {
							font-size: 18px;
						}

						&:hover {
							border-color: #f7f7f7;

							span {
								font-size: 19px;
								transition-duration: 0.5s;
								background: darken($secondary-color, 5%);
							}
						}
					}
				}
			}
		}

		main {
			padding-top: 94px !important;

			>section {
				padding-top: 100px;
			}
		}

		#section-news {
			>div {
				ul {
					li {
						display: table;
						margin-bottom: 15px;

						time,
						a {
							display: table-cell;
						}

						time {
							border: none;
							padding: 0;
							margin: 0;
							font-size: 15px;
						}

						a {
							padding-left: 20px;
						}
					}
				}
			}
		}

		#section-about,
		#section-training {
			>div {
				>section {
					>h1 {
						margin-top: 40px;
					}

					&#readers {
						>section {
							ul {
								div {
									p {
										height: 84px;
									}
								}
							}
						}
					}

					&#objectives {
						>div {
							background: #f1f1f1;
							padding: 10px;

							>ul {
								>li {
									display: inline-block;
									width: 32%;
									margin-left: 1%;
									vertical-align: top;

									&:first-child {
										margin-left: 0;
									}

									section {
										background: $white;

										h1 {
											min-height: 70px;
											display: table;
											width: 100%;

											span {
												display: table-cell;
												vertical-align: middle;
											}
										}

										figure {
											min-height: 131px;
										}

										ul {
											text-align: left;
											padding: 5px 20px 20px;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		#section-editor {
			>div {
				>section {
					h1 {
						margin-bottom: 30px;
					}

					ul {
						li {
							width: 49%;
							text-align: left;

							figure {
								width: 158px;
							}
						}
					}

					&.executive-editor {
						margin: 40px 0;

						ul li {
							margin: 0 auto;
						}
					}

					&.editor {
						ul {
							overflow: hidden;

							li {
								float: left;

								&:nth-child(odd) {
									margin-right: 2%;
								}
							}
						}
					}
				}
			}
		}

		#section-download {
			>div {
				>p {
					margin-bottom: 30px;
				}
			}
		}

		#section-inquiry {
			padding-bottom: 100px;

			>div {
				>p {
					text-align: center;
					margin-bottom: 30px;
				}

				form {
					max-width: 80%;
					margin: 0 auto;

					#label-email {
						width: 80%;
					}

					#label-details {
						min-height: 120px;
					}
				}
			}
		}


	}

}