// archive
// ---------

body.archive {
	ul.news-list {
		list-style: none;
		margin: 0;

		li {
			margin-bottom: 10px;

			time {
				color: #666;
				font-size: 13px;
				display: block;
				border-bottom: 1px dotted #ddd;
				padding-bottom: 3px;
				margin-bottom: 3px;
				width: 100px;

				&:before {
					font-family: FontAwesome;
					content: "\f073";
					margin-right: 6px;
					font-size: 12px;
				}
			}
		}

		@include breakpoint(large up) {
			li {
				display: table;
				margin-bottom: 15px;

				time,
				a {
					display: table-cell;
				}

				time {
					border: none;
					padding: 0;
					margin: 0;
					font-size: 15px;
				}

				a {
					padding-left: 20px;
				}
			}
		}
	}

	/*wp-pagenavi base*/
	.wp-pagenavi {
		text-align: center;
		margin-top: 15px;

		@include breakpoint(medium up) {
			margin-top: 50px;
		}

		a,
		span {
			display: inline-block;
			color: #999;
			background-color: #FFF;
			border: solid 1px #e0e0d2;
			padding: 7px 15px;
			margin: 0 2px;
			white-space: nowrap;
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			border-radius: 3px;
			-webkit-transition: 0.2s ease-in-out;
			-moz-transition: 0.2s ease-in-out;
			-o-transition: 0.2s ease-in-out;
			transition: 0.2s ease-in-out;
			text-align: center;
			text-decoration: none;
		}

		a:hover {
			color: #FFF;
			background-color: #999;
			border-color: #999;
		}

		span.current {
			color: #FFF;
			background-color: $primary-color;
			border-color: $primary-color;
			font-weight: bold;
		}
	}
}

// single
// ---------

body.single {
	#wrap {
		main {
			>article {
				>h1 {
					font-size: 18px;

					@include breakpoint(medium up) {
						font-size: 26px;
					}

					@include breakpoint(large up) {
						font-size: 32px;
						margin-bottom: 30px;
					}
				}

				ul.pager-single {
					list-style: none;
					margin: 20px 0 50px;
					text-align: center;

					li {
						display: inline-block;

						a {
							@extend .button;
							width: 120px;
							border-radius: 3px !important;
							padding: 3px 5px;
						}

						&:first-child {
							margin-right: 20px;

							a:before {
								font-family: FontAwesome;
								content: "\f060";
								margin-right: 10px;
							}
						}

						&:last-child {
							a:after {
								font-family: FontAwesome;
								content: "\f061";
								margin-left: 10px;
							}
						}
					}
				}

				.row {

					.column,
					.columns {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}

// inquiry
// ---------

body.page-template {
	main {
		#section-inquiry {
			padding-bottom: 30px;

			form {
				label {
					margin-bottom: 0;
					color: $primary-color;
				}
			}

			a.button,
			button[type="submit"] {
				border-radius: 60px;
				padding: 10px 30px 10px 40px;
				position: relative;
				margin: 20px 0 0;

				&:before {
					font-family: FontAwesome;
					content: "\f138";
					font-size: 12px;
					position: absolute;
					top: 11px;
					left: 10px;
					color: $white;
				}
			}

			@include breakpoint(medium up) {
				label.middle {
					margin: 0;
					padding: 0 0 20px;
				}
			}

			@include breakpoint(large up) {
				padding-bottom: 100px;

				>div {
					>p {
						text-align: center;
						margin-bottom: 30px;
					}

					form {
						max-width: 80%;
						margin: 0 auto;

						#label-email,
						#label-category {
							width: 80%;
						}

						#label-details {
							min-height: 120px;
						}
					}
				}
			}
		}
	}
}

// cikf-entry
// ---------

body.page-template {
	main {
		#section-cikf-entry {
			padding-bottom: 30px;

			form {
				label {
					margin-bottom: 0;
					color: $primary-color;
				}
			}

			a.button,
			button[type="submit"] {
				border-radius: 60px;
				padding: 10px 30px 10px 40px;
				position: relative;
				margin: 20px 0 0;

				&:before {
					font-family: FontAwesome;
					content: "\f138";
					font-size: 12px;
					position: absolute;
					top: 11px;
					left: 10px;
					color: $white;
				}
			}

			@include breakpoint(medium up) {
				label.middle {
					margin: 0;
					padding: 0 0 20px;
				}
			}

			@include breakpoint(large up) {
				padding-bottom: 100px;

				>div {
					>p {
						text-align: center;
						margin-bottom: 30px;
					}

					>.notes {
						font-size: 18px;
						padding-bottom: 10px;
						margin-bottom: 15px;
					}

					form {
						max-width: 80%;
						margin: 0 auto;

						#label-email,
						#label-category {
							width: 80%;
						}

						#label-details {
							min-height: 120px;
						}
					}
				}
			}

			>div {
				>p {
					text-align: center;
				}

				>.notes {
					color: $primary-color;
					font-weight: bold;
					border-bottom: 1px solid;
				}

				form {
					input+.error {
						position: relative;
						top: -1em;
					}
				}
			}
		}
	}
}

// store
// ---------

body.page-template-store {
	section#section-store {
		padding-bottom: 100px;

		a.button {
			border-radius: 60px;
			padding: 10px 30px 10px 40px;
			position: relative;
			margin: 20px 0 0;

			&:before {
				font-family: FontAwesome;
				content: "\f138";
				font-size: 12px;
				position: absolute;
				top: 11px;
				left: 10px;
				color: $white;
			}
		}

		section.notes {
			>h1 {
				color: $primary-color;
				font-size: 18px;
				font-weight: bold;
				border-bottom: 1px solid;
				padding-bottom: 10px;
				margin-bottom: 15px;

				&:before {
					font-family: FontAwesome;
					content: "\f1db";
					margin-right: 6px;
					font-size: 13px;
					font-weight: normal;
					vertical-align: 2px;
				}
			}

			>dl {
				>dd {
					margin-bottom: 30px;

					dl {
						padding-left: 1rem;
					}
				}
			}

			em {
				color: $primary-color;
			}
		}

		@include breakpoint(small only) {
			padding-bottom: 30px;

			figure {
				text-align: center;
				margin-bottom: 20px;
			}

			.medium-8>div {
				text-align: center;
			}
		}
	}
}

// Privacy Policy
// ---------

body.page-template-privacy {
	section#section-privacy {
		padding-bottom: 100px;

		ol {
			margin-bottom: 30px;
		}

		dl.contact {
			dt {
				color: $primary-color;
				border-bottom: 1px solid;
				padding-bottom: 10px;
				margin-bottom: 15px;
			}
		}

		@include breakpoint(small only) {
			padding-bottom: 30px;
		}
	}
}

// CIKF
// ---------

body.page-template-cikf {
	.section-banner {
		text-align: center;
		padding: 42px 10px 0 10px;

		@include breakpoint(medium up) {
			padding-top: 100px;
		}
	}

	#section-company {
		table {
			width: 100%;
			border: 1px solid #ddd;
			border-collapse: collapse;
			margin-bottom: 0;

			tbody {
				border: none;

				th,
				td {
					padding: 15px;
				}
			}

			tr {
				background: none;

				&:not(:last-child) {
					border-bottom: 1px solid #ddd;
				}
			}

			th {
				border-right: 1px solid #ddd;
				background: #f7f7f7;
				width: 20%;

				small {
					display: inline-block;
				}
			}

			td {
				span {
					font-weight: bold;
				}
			}

			ul,
			ol {
				margin-bottom: 0;
			}

			@include breakpoint(small only) {
				display: block;
				border: none;

				tbody {

					th,
					td {
						display: block;
						padding: 0;
					}
				}

				tr {
					display: block;

					&:not(:last-child) {
						border-bottom: none;
						margin-bottom: 15px;
					}
				}

				th {
					background: none;
					border-right: none;
					color: $primary-color;
					border-bottom: 1px dotted;
					width: 100%;
					text-align: left;
					padding-bottom: 6px !important;
					margin-bottom: 10px;
				}
			}
		}
	}

	#section-business {
		>div>div {
			background: rgba($primary-color, 0.05);
			padding: 15px;

			@include breakpoint(medium up) {
				padding: 30px;
			}

			ol {
				color: $primary-color;
				font-weight: bold;
				margin-bottom: 0;

				li {
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
			}
		}
	}

	#section-member-recruitment {
		margin-bottom: 30px;

		@include breakpoint(medium up) {
			margin-bottom: 100px;
		}

		section {
			margin-bottom: 20px;

			>h1 {
				color: $primary-color;
				font-size: 18px;
				font-weight: bold;
				border-bottom: 1px solid;
				padding-bottom: 10px;
				margin-bottom: 15px;

				&:before {
					font-family: FontAwesome;
					content: "\f1db";
					margin-right: 6px;
					font-size: 13px;
					font-weight: normal;
					vertical-align: 2px;
				}
			}
		}

		table {
			border: 1px solid #ddd;
			border-collapse: collapse;
			font-size: 14px;
			width: 100%;

			thead,
			tbody {

				th,
				td {
					text-align: center;
				}
			}

			thead {
				border: none;

				tr {
					border-bottom: 1px solid #ddd;
				}

				th {
					&:not(:last-child) {
						border-right: 1px solid #ddd;
					}
				}
			}

			tbody {
				border: none;

				tr {
					background: none;

					&:not(:last-child) {
						border-bottom: 1px solid #ddd;
					}
				}

				td {
					width: 30%;

					&:not(:last-child) {
						border-right: 1px solid #ddd;
					}
				}
			}
		}

		dl {
			ol {
				padding-left: 1rem;
			}
		}

		a.button {
			border-radius: 60px;
			background: $secondary-color;
			color: $primary-color;
			padding: 10px 30px 10px 40px;
			position: relative;

			&:before {
				font-family: FontAwesome;
				content: "\f138";
				font-size: 12px;
				position: absolute;
				top: 11px;
				left: 10px;
				color: $white;
			}

			&:hover {
				background: darken($secondary-color, 5%);
			}
		}
	}
}

// NEWS 2018/07/05
// ---------
#news_20180705 {
	table {
		width: 100%;
		border: 1px solid #ddd;
		border-collapse: collapse;
		margin-bottom: 30px;

		thead {
			background: none;
			border: none;
			border-bottom: 1px solid #ddd;

			th {
				background: none;
				text-align: center;
				padding: 30px;
				line-height: 2;

				span {
					font-size: 20px;
				}
			}
		}

		tbody {
			border: none;

			th,
			td {
				padding: 15px;
			}
		}

		tr {
			background: none;

			&:not(:last-child) {
				border-bottom: 1px solid #ddd;
			}
		}

		th {
			border-right: 1px solid #ddd;
			background: #f7f7f7;
			width: 20%;

			small {
				display: inline-block;
			}
		}

		ul,
		ol {
			margin-bottom: 0;
		}

		iframe {
			width: 100%;
			height: 350px;
		}

		@include breakpoint(small only) {
			display: block;
			border: none;

			thead {
				border-bottom: none;

				tr {
					margin-bottom: 15px;
				}

				th {
					padding: 0;
					line-height: 1.8;
					border-bottom: 2px solid $primary-color;
					font-size: 15px;

					span {
						font-size: 18px;
					}
				}
			}

			tbody {

				th,
				td {
					display: block;
					padding: 0;
				}
			}

			tr {
				display: block;

				&:not(:last-child) {
					border-bottom: none;
					margin-bottom: 15px;
				}
			}

			th {
				background: none;
				border-right: none;
				color: $primary-color;
				border-bottom: 1px dotted;
				width: 100%;
				text-align: left;
				padding-bottom: 6px !important;
				margin-bottom: 10px;
			}
		}
	}

	table td>dl {
		>dt {
			color: $primary-color;
			border: 1px solid $primary-color;
			padding: 5px;
			border-left: 7px solid $primary-color;
			margin-bottom: 10px;

			span {
				display: inline-block;
				margin-left: 5px;
			}
		}

		>dd {
			margin-bottom: 20px;

			dl {
				dt {
					&:before {
						content: "【";
					}

					&:after {
						content: "】";
					}
				}

				dd {
					margin-bottom: 15px;
					padding-left: 1rem;
				}
			}
		}
	}

	>section {
		>h1 {
			background: #f1f1f1;
			font-size: 22px;
			text-align: center;
			font-weight: bold;
			padding: 10px;
			margin-left: -10px;
			margin-right: -10px;
			margin-bottom: 30px;
		}

		>ul {
			list-style: none;
		}

		&#section-member {
			text-align: center;
			font-size: 14px;
			margin-bottom: 30px;

			ul {
				li {
					min-height: 170px;

					&:last-child {
						float: left;
					}

					a:hover img {
						opacity: 0.7;
					}
				}
			}
		}

		&.section-speaker {
			>ul {
				margin-left: 0;
				margin-bottom: 30px;
				text-align: center;

				>li {
					padding-bottom: 20px;
					margin-bottom: 20px;

					&:not(:last-child) {
						border-bottom: 1px solid #ddd;
					}

					figure {
						margin-bottom: 10px;
					}

					>div {
						p {
							&:nth-child(1) {
								font-size: 13px;
								margin-bottom: 6px;
							}

							&:nth-child(2) {
								font-weight: bold;
							}

							&:nth-child(3) {
								text-align: left;
							}
						}

						ul {
							text-align: left;
						}
					}
				}

				@include breakpoint(medium up) {
					text-align: left;

					>li {
						display: table;
						width: 100%;

						>figure,
						>div {
							display: table-cell;
							vertical-align: top;
						}

						>figure {
							width: 138px;
						}

						>div {
							padding-left: 30px;
						}
					}
				}
			}

			&.panelist {
				>ul>li:last-child {
					border-bottom: 1px solid #ddd;
				}
			}
		}
	}

	span.button {
		margin-bottom: 10px;

		+span {
			color: $alert-color;
			font-weight: bold;
			display: block;
		}
	}
}


// CIBOK Study
// ---------

main#cibok-study {
	>section {
		margin-bottom: 30px !important;

		@include breakpoint(large up) {
			margin-bottom: 100px !important;
		}

		>div {
			padding-top: 42px !important;
			margin-bottom: 50px;

			@include breakpoint(large up) {
				padding-top: 100px !important;
				margin-bottom: 70px;
			}
		}
	}

	nav.box {
		border: 1px solid $medium-gray;
		padding: 10px;
		margin-bottom: 50px;

		@include breakpoint(large up) {
			padding: 30px;
			margin-bottom: 70px;
		}
	}

	table {
		width: 100%;
		border: 1px solid #ddd;
		border-collapse: collapse;
		margin-bottom: 0;

		tbody {
			border: none;

			th,
			td {
				padding: 15px;
			}
		}

		tr {
			background: none;

			&:not(:last-child) {
				border-bottom: 1px solid #ddd;
			}
		}

		th {
			border-right: 1px solid #ddd;
			background: #f7f7f7;
			width: 20%;

			small {
				display: inline-block;
			}
		}

		td {
			span {
				font-weight: bold;
			}
		}

		ul,
		ol {
			margin-bottom: 0;
		}

		@include breakpoint(small only) {
			display: block;
			border: none;

			tbody {

				th,
				td {
					display: block;
					padding: 0;
				}
			}

			tr {
				display: block;

				&:not(:last-child) {
					border-bottom: none;
					margin-bottom: 15px;
				}
			}

			th {
				background: none;
				border-right: none;
				color: $primary-color;
				border-bottom: 1px dotted;
				width: 100%;
				text-align: left;
				padding-bottom: 6px !important;
				margin-bottom: 10px;
			}
		}
	}

	h5 {
		font-size: 16px;
		font-weight: bold;
	}

	h6 {
		margin-bottom: 0;
	}

	ol>li {
		margin-bottom: 10px;
	}

	p.annotation {
		font-size: 13px;
		color: $dark-gray;
	}
}


// CIKF Training
// ---------

main#cikf-training {
	>section>div>h2 {
		font-size: 18px;
		font-weight: bold;
		color: $primary-color;
	}

	table {
		width: 100%;
		border: 1px solid #ddd;
		border-collapse: collapse;
		margin-bottom: 70px;

		tbody {
			border: none;

			th,
			td {
				padding: 15px;
			}
		}

		tr {
			background: none;

			&:not(:last-child) {
				border-bottom: 1px solid #ddd;
			}
		}

		th {
			border-right: 1px solid #ddd;
			background: #f7f7f7;
			width: 20%;

			small {
				display: inline-block;
			}
		}

		td {
			span {
				font-weight: bold;
			}
		}

		ul,
		ol {
			margin-bottom: 0;
		}

		@include breakpoint(small only) {
			display: block;
			margin-bottom: 20px;
			border: 3px solid rgba($primary-color, .3);
			padding: 13px;

			tbody {

				th,
				td {
					display: block;
					padding: 0;
				}
			}

			tr {
				display: block;

				&:not(:last-child) {
					border-bottom: none;
					margin-bottom: 15px;
				}
			}

			th {
				background: none;
				border-right: none;
				color: $primary-color;
				border-bottom: 1px dotted;
				width: 100%;
				text-align: left;
				padding-bottom: 6px !important;
				margin-bottom: 10px;
			}
		}
	}
}