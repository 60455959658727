// reset & common class
// ---------

.mb0 {
	margin-bottom: 0;
}
.mb20 {
	margin-bottom: 20px;
}
.mb30 {
	margin-bottom: 30px;
}
.mb40 {
	margin-bottom: 40px;
}
em {
	font-style: normal !important;
	font-weight: bold;
}
.border-left {
	border-left: 7px solid rgba($primary-color, 0.6);
	padding: 5px 0 5px 15px;
	margin-left: 1rem;
}

html,body {
  height: 100%;
  #wrap {
    width: 100%;
    position: relative;
    min-height: 100%;
    
// header
// ---------
    
    > header {
      .title-bar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 900;
        width: 100%;
        height: 42px;
        border-bottom: 1px solid #eee;
        .title-bar-title {
          max-width: 258px;
          img {
            vertical-align: 0.3px;
          }
        }
      }
      nav {
        display: none;
        position: fixed;
        top: 42px;
        left: 0;
        z-index: 800;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        ul {
          list-style: none;
          margin: 0;
          background: none;
          li {
            font-size: 13px;
            font-weight: bold;
            border-bottom: 1px solid rgba($white, 0.6);
            a {
              display: block;
              color: $white;
              padding: 10px 0;
              position: relative;
              &:after {
                font-family: FontAwesome;
                content: "\f138";
                position: absolute;
                top: 13px;
                right: 0;
                font-size: 11px;
              }
            }
            &.hide-for-large {
              display: inline-block;
              width: 49%;
              border: none;
              text-align: center;
              padding: 15px 0;
              a {
                border: 1px solid $white;
                display: inline-block;
                padding: 3px 20px;
                &:after {
                  content: "";
                }
              }
            }
          }
        }
      }
    }
    
// footer
// ---------
    #back-to-top a {
      background: rgba($primary-color, 0.8);
      color: $white;
      width: 50px;
      height: 50px;
      border-radius: 3px;
      position: fixed;
      bottom: 43px;
      right: 10px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      padding-top: 8px;
      i {
        display: block;
      }
    }
    
    > footer {
      width: 100%;
      height: 40px;
      position: absolute;
      bottom: 0;
      background: $black;
      text-align: center;
      p {
        font-size: 11px;
        color: $white;
        padding-top: 10px;
        margin: 0;
      }
    }
    
// main
// ---------
    
    main {
      padding-top: 50px;
      padding-bottom: 40px;
      > section, > article {
        padding-left: 10px;
        padding-right: 10px;
        > h1 {
          text-align: center;
          color: $primary-color;
          font-weight: bold;
        }
        > div {
          border-top: 10px solid $primary-color;
          -webkit-box-shadow: 0 0 3px #ccc;
          -moz-box-shadow: 0 0 3px #ccc;
          box-shadow: 0 0 3px #ccc;
          padding: 15px 10px;
          word-break: break-word;
        }
      }
      ul.breadcrumbs {
        width: 100%;
        li:last-child {
          max-width: 55%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  
  
  @include breakpoint(medium up) {
    #wrap {
      main {
        > section, > article {
          padding-left: 30px;
          padding-right: 30px;
          > h1 {
            font-size: 32px;
          }
          > div {
            padding: 30px;
          }
        }
      }
      #back-to-top a {
        right: 20px;
      }
      
    }
  }
  
  
  @include breakpoint(large up) {
    #wrap {
      > header {
        position: fixed;
        z-index: 900;
        top: 0;
        left: 0;
        width: 100%;
        .title-bar {
          display: none;
        }
        .title-bar-large {
          display: block;
          background: $white;
          height: 50px;
          .inner {
            max-width: $global-width;
            margin: 0 auto;
            overflow: hidden;
            h1 {
              float: left;
              line-height: 0;
              margin: 0;
              padding-top: 14px;
              width: 517px;
            }
            ul {
              float: right;
              text-align: right;
              list-style: none;
              padding-top: 12px;
              li {
                display: inline-block;
                margin-left: 6px;
                a {
                  color: $body-font-color;
                  border: 1px solid;
                  padding: 3px 8px;
                  font-size: 13px;
                  &:before {
                    font-family: FontAwesome;
                    content: "\f105";
                    margin-right: 10px;
                    color: #222;
                  }
                  &:hover {
                    background: #f1f1f1;
                  }
                }
              }
            }
          }
        }
        nav {
          position: relative;
          top: 0;
          height: 44px;
          ul {
            text-align: center;
            li {
              display: inline-block;
              margin: 0 7px;
              border: none;
              &:first-child {
                margin-left: 0;
              }
              &:last-child {
                margin-right: 0;
              }
              a {
                &:after {
                  display: none;
                }
                &:hover {
                  color: $secondary-color;
                }
              }
            }
          }
        }
      }
      main {
        padding-top: 144px;
        > section, > article {
          max-width: $global-width;
          margin: 0 auto;
          padding-left: 10px;
          padding-right: 10px;
          > div {
            padding: 40px;
          }
        }
      }
    }
  }
}