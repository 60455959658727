body.single {
  article {
    > div > section {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
  h2 {
    border-left: 4px solid $primary-color;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    margin-bottom: 1em;
    line-height: 1.7;
    @include breakpoint(medium up) {
      font-size: 22px;
      padding-left: 15px;
    }
  }
  h3 {
    font-size: 17px;
    font-weight: bold;
    border-bottom: 1px solid rgba($primary-color, .1);
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 15px;
    line-height: 1.7;
    &::after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 1px;
      width: 20%;
      max-width: 80px;
      background: $primary-color;
    }
    @include breakpoint(medium up) {
      font-size: 18px;
      margin: 20px 0 15px;
    }

  }
  h4 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.7;
    border-bottom: 1px dotted;
    padding-bottom: 5px;
    margin: 10px 0;
    &.blue {
      color: $primary-color;
    }
  }
  h5 {
    font-size: 16px;
    font-weight: bold;
  }
  ul, ol {
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  figure {
    margin: 15px 0 20px;
  }
  .box {
    border: 1px solid $medium-gray;
    padding: 15px;
    border-radius: 3px;
    margin: 10px 0 20px;
    @include breakpoint(medium up) {
      padding: 30px;
    }
  }
  table {
    th,td {
      border: 1px solid $medium-gray;
    }
    thead {
      th {
        background: $medium-gray;
        text-align: center;
      }
    }
    tbody {
      tr {
        th {
          width: 6em;
          @include breakpoint(medium up) {
            width: 15em;
          }
        }
      }
    }
  }
}
